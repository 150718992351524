body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.columnular {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-content: center;
    }

    .containerctr {
      margin:0 auto;
      width: 60%;
      display: flex;
      flex-direction: column;
      align-content: center;
      padding-bottom: 40px;
        }

        input {
          padding: 8px;
          width: 100%;
        }

        textarea {
          padding: 8px;
          width: 100%;
        }